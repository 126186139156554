import request from '@/utils/request'
// import qs from "qs";

export function queryUserExam(data) {
  return request({
    url: 'userExam/queryUserExam',
    method: 'get',
    params: data,
  })
}

// 查询考试名称
export function queryReply(data) {
  return request({
    url: 'reply/queryReply',
    method: 'get',
    params: data,
  })
}